import { Box } from "@mui/material";
import Img1 from "../../assets/images/products/crypto-offering/card1.png";
import Img2 from "../../assets/images/products/crypto-offering/card2.png";
import Img3 from "../../assets/images/products/crypto-offering/card3.png";
import Img4 from "../../assets/images/products/crypto-offering/card4.png";
import Carousel from "../Carousel";
import ThematicIndexesCarouselCard from "../ThematicIndexesCarousel/Card";
import { CAROUSEL_BREAKPOINTS } from "./data";

const CryptoOfferingCarousel = () => {
  return (
    <Box pt={3}>
      <Carousel slidesPerView={4} spaceBetween={20} breakpoints={CAROUSEL_BREAKPOINTS}>
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link="https://bitadata.com/index/B10"
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link="https://bitadata.com/index/BCDFSI"
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link="https://bitadata.com/index/BCMVSI"
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Next 20 Index"
          link="https://bita.io/index/BCN20I"
        />
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link="https://bitadata.com/index/B10"
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link="https://bitadata.com/index/BCDFSI"
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link="https://bitadata.com/index/BCMVSI"
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Next 20 Index"
          link="https://bita.io/index/BCN20I"
        />
      </Carousel>
    </Box>
  );
};

export default CryptoOfferingCarousel;
