import { FC, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import ImgBg from "../../assets/images/products/thematic-data/bg-data-card.png";
import {
  CardContainer,
  CardTextMain,
  CardTitle,
  CardTextNotFlipped,
  CardTextFlipped,
  List,
  CardAction,
  IconWrapper,
  FooterActionLabel,
} from "./styles";

interface Props {
  image: any;
  title: string;
  text: string;
  reverseTexts: string[];
}

const ThematicDataCarouselCard: FC<Props> = ({
  image,
  title,
  text,
  reverseTexts,
}) => {
  const [flipped, setFlipped] = useState<boolean>(false);

  const toggleFlipped = () => setFlipped(!flipped);

  return (
    <CardContainer>
      <Box sx={{ zIndex: -1 }}>
        <img src={image} alt={title} />
      </Box>

      <CardTextMain
        sx={{
          height: flipped ? "auto" : "20rem",
          backgroundImage: `url(${ImgBg})`,
        }}
      >
        {!flipped && <CardTitle>{title}</CardTitle>}
        {flipped ? (
          <CardTextFlipped>
            <List>
              {reverseTexts.map((str, idx) => (
                <li key={str + idx}>{str}</li>
              ))}
            </List>
          </CardTextFlipped>
        ) : (
          <CardTextNotFlipped>{text}</CardTextNotFlipped>
        )}
        <CardAction
          sx={{
            borderColor: flipped ? "#fff" : "transparent",
            padding: flipped ? "0.5rem" : "0.5rem 0",
          }}
          onClick={toggleFlipped}
        >
          {flipped ? (
            <>
              <FooterActionLabel>Return</FooterActionLabel>
              <IconWrapper>
                <KeyboardArrowLeftIcon htmlColor="#fff" />
              </IconWrapper>
            </>
          ) : (
            <>
              <FooterActionLabel>Themes</FooterActionLabel>
              <IconWrapper
                sx={{
                  backgroundColor: "#fff",
                }}
              >
                <KeyboardArrowRightIcon htmlColor="#792785" />
              </IconWrapper>
            </>
          )}
        </CardAction>
      </CardTextMain>
    </CardContainer>
  );
};

export default ThematicDataCarouselCard;
