import React, {FC, useRef} from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwiperInstance from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStyles } from "./styles";
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
interface GenericSwiperProps {
  children: React.ReactNode;
  slidesPerView?: number;   
  spaceBetween?: number;      
  navigation?: boolean;
  //TODO: define types for the below properties with type 'any'
  options?: any;
  breakpoints?: any;
}

const GenericSwiper: FC<GenericSwiperProps> = ({
  children,
  spaceBetween = 10,
  breakpoints = {},
  navigation= true
}) => {
  const swiperRef = useRef<SwiperInstance | null>(null);
  
  const classes = useStyles();
  return (
    <div className={classes.swiperContainer}>
        {navigation && (
          <button
            onClick={() => swiperRef.current?.slidePrev()}
            className={`${classes.swiperArrowButton} ${classes.left}`}
          >
            <ArrowBackIosNewIcon className={classes.swipperArrow}/>
          </button>
        )}

      <Swiper
        spaceBetween={spaceBetween}
        onSwiper={(swiper: any) => (swiperRef.current = swiper)}
        breakpoints={breakpoints}
        loop={true}
      >
        {React.Children.map(children, (child) => (
          <SwiperSlide className={classes.swiperSlide}>{child}</SwiperSlide>
        ))}
      </Swiper>
      {navigation && (
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className={`${classes.swiperArrowButton} ${classes.right}`}
        >
          <ArrowForwardIosIcon className={classes.swipperArrow}/>
        </button>
        )
      }
    </div>
  );
};

export default GenericSwiper;