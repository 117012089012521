export const CAROUSEL_BREAKPOINTS = {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    480: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    600: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 1,
    },
    1440: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
    1920: {
      slidesPerView: 4,
      slidesPerGroup: 1,
    },
  }