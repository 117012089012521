import { styled } from "@mui/system";

const linkComponent = {
  color: "#fff !important",
  textDecoration: "none !important",
};

const Container = styled("div")(({ theme }) => ({
  position: "relative",
  height: "18rem",
  display: "flex",
  flexDirection: "column",
  margin: "0 14px",
  padding: "1.25rem",
  overflow: "hidden",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%", // Default width

  [theme.breakpoints.up("sm")]: {
    width: "240px", // Apply this width on small screens and above
  },
}));

const TypeWrapper = styled("div")(({
  fontSize: 11,
  fontWeight: 500,
  textTransform: "uppercase",
}));

const TypeText ={
  borderBottom: "1px solid #fff",
  paddingBottom: 4,
};

const Title = styled("h2")(({
  maxWidth: "70%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: 18,
  lineHeight: 1.2,
  fontWeight: 600,
  paddingBottom: "0.75rem",
}));

const LearnMore = styled("div")(({
  fontSize: 13,
  fontWeight: 700,
}));

export { linkComponent, Container, TypeWrapper, TypeText, Title, LearnMore };



