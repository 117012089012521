import { FC } from "react";
import { Box } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import Carousel from "../Carousel";
import { CAROUSEL_BREAKPOINTS, CLIENT_LIST, CLIENT_LIST_DESKTOP } from "./data";
import {
  Container,
  rowComponent,
  item,
  imageComponent,
  MobileContainer,
} from "./styles";

// Get the row style based on the IDs. SVG logos have different sizes, that's why I'm generating different styling for each row of the slice
const getRowStyle = (row: Array<{id: number, label: string, image: string}>) => {
  if (row.some(item => [4, 5, 6, 7].includes(item.id))) {
    return { marginTop: '15px' };
  } else if (row.some(item => [8, 9, 10, 11].includes(item.id))) {
    return { marginTop: '20px' };
  } else if (row.some(item => [16, 17, 18, 19].includes(item.id))) {
    return { marginTop: '40px' };
  }
  return {};
};

const HomeClientsList: FC<{}> = () => {
  const inMobile = useIsMobileView();

  if (inMobile) {
    return (
      <MobileContainer>
        <Carousel
          breakpoints={CAROUSEL_BREAKPOINTS}
        >
          {CLIENT_LIST.map(({ id, label, image }) => (
            <Box key={id} sx={{ ...item }}>
              <img style={{ ...imageComponent }} src={image} alt={label} />
            </Box>
          ))}
        </Carousel>
      </MobileContainer>
    );
  }

  return (
    <Container>
      <Carousel
      spaceBetween={5}
      >
        {CLIENT_LIST_DESKTOP.map((sublist, idx) => (
          <Box key={idx}>
            {sublist.map((row, rowIdx) => {
              const rowStyle = getRowStyle(row);

              return (
                <Box key={rowIdx} px={2} sx={{ ...rowComponent, ...rowStyle }}>
                  {row.map(({ id, image, label }) => (
                    <Box sx={{ ...item }} key={id}>
                      <img
                        key={id}
                        style={{ ...imageComponent }}
                        src={image}
                        alt={label}
                      />
                    </Box>
                  ))}
                </Box>
            )})}
          </Box>
        ))}
      </Carousel>
    </Container>
  );
};

export default HomeClientsList;