import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import useWebSocket, { ReadyState } from "react-use-websocket";
import ImgLogoPlaceholder from "../../assets/images/products/crypto-offering/Crypto_placeholder_logo.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { selectAnalyzerToken } from "../../redux/slices/auth";
import {
  fetchTokenPriceIndexEOD,
  selectTokenPriceIndexEOD,
} from "../../redux/slices/indexes";
import { socketUrl } from "../../services";
import { getMultiCalc, getMultiCalcLegacy } from "../../services/analyzer";
import { MultiCalc } from "../../types/analyzer";
import { Cryptocurrency } from "../../types/assets";
import { checkFeatureFlagEnabled } from "../../utils/env-flgs";
import { encodeSpacesInUrl } from "../../utils/imageParser";
import Carousel from "../Carousel";
import CryptoOfferingCoin from "../CryptoOfferingCoin";
import SearchInput from "../SearchInput";
import { Header, Title, Item, Selected } from "./styles";

interface Props {
  cryptocurrencies: Cryptocurrency[];
}

const CryptoFeeds: FC<Props> = ({ cryptocurrencies }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const analyzerToken = useAppSelector(selectAnalyzerToken);
  const tokenPriceEod = useAppSelector(selectTokenPriceIndexEOD);
  const inMobile = useIsMobileView();

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl);

  const [selected, setSelected] = useState<Cryptocurrency>();
  const [data, setData] = useState<MultiCalc[]>([]);
  const [error, setError] = useState<any>(null);
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const calcInput = useMemo(() => {
    if (!selected || !tokenPriceEod[selected.ticker]) {
      return {};
    }

    return tokenPriceEod[selected.ticker].reduce((acc, item) => {
      const { timestamp, value } = item;
      return { ...acc, [moment(timestamp).format("yyyy-MM-DD")]: value };
    }, {});
  }, [selected, tokenPriceEod]);

  const items: Cryptocurrency[] = useMemo(() => {
    return cryptocurrencies.filter((c) => {
      if (c.status !== "A") return false;
      if (query.length > 2) {
        return (
          c.name.toLowerCase().includes(query) ||
          c.ticker.toLowerCase().includes(query)
        );
      }
      return true;
    });
  }, [cryptocurrencies, query]);

  const chartData = useMemo(() => {
    if (!selected || !tokenPriceEod[selected.ticker]) {
      return [];
    }

    return tokenPriceEod[selected.ticker].map((item) => {
      const { timestamp, value } = item;
      return [moment(timestamp).valueOf(), value]; // Need to convert date string to timestamp: required by Highcharts
    });
  }, [selected, tokenPriceEod]);

  const realTimeValue = useMemo(() => {
    try {
      const data = JSON.parse(lastMessage?.data ?? "{}");

      // Pick only correct data
      if (
        data.channel === "live" &&
        data.event === "update" &&
        data.id === selected?.ticker &&
        (data?.data?.index_value || data?.data?.price)
      ) {
        return data?.data?.index_value || data.data.price;
      }
    } catch (error) {}
  }, [lastMessage?.data, selected?.ticker]);

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (items?.length) {
      setSelected(items[0]);
    }
  }, [items]);

  useEffect(() => {
    if (!selected) return;
    const ids = [selected.ticker];
    const startDate = moment().add(-1.5, "years").format("YYYY-MM-DD"); // 1 year ago
    const endDate = moment().add(-1, "days").format("YYYY-MM-DD"); // Yesterday

    dispatch(
      fetchTokenPriceIndexEOD({
        assets: ids,
        startDate,
        endDate,
      })
    );
  }, [dispatch, selected]);

  const fetchMultiCalcData = useCallback(async () => {
    try {
      if (analyzerToken && calcInput) {
        setIsLoading(true);
        const resp = await (checkFeatureFlagEnabled("USE_NEW_ANALYZER_API")
          ? getMultiCalc(analyzerToken, calcInput)
          : getMultiCalcLegacy(analyzerToken, calcInput));
        if (resp) {
          setData(resp.result);
          setError(null);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      console.error(error);
      setError(error);
    }
  }, [analyzerToken, calcInput]);

  useEffect(() => {
    fetchMultiCalcData();
  }, [fetchMultiCalcData]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        "Failed to fetch multi calculation data. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          preventDuplicate: true,
        }
      );
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && selected?.ticker) {
      // Subscribe to index channel
      sendJsonMessage({
        event: "subscribe",
        id: selected?.ticker,
        channel: "live",
      });
    }
  }, [readyState, sendJsonMessage, selected?.ticker]);

  useEffect(() => {
    if (selected?.crypto_id) {
      setIsLoading(true);
    }
  }, [selected]);

  return (
    <Box>
      <Header>
        <Title>Check our Crypto data:</Title>
        <Box>
          <SearchInput
            label=""
            placeholder="Search by Name or Ticker..."
            onChange={handleChangeQuery}
            fullWidth={inMobile}
            colorScheme="white"
          />
        </Box>
      </Header>
      <Box pt={3}>
        <Carousel
        slidesPerView= {8}
        spaceBetween={0}
        breakpoints={{
          0: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          480: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          600: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 8,
            slidesPerGroup: 1,
          },
          1440: {
            slidesPerView: 8,
            slidesPerGroup: 1,
          },
          1920: {
            slidesPerView: 8,
            slidesPerGroup: 1,
          },
        }}
        >
          {items.map((item) => (
            <Box key={item.crypto_id}>
              <Item
                className={`${
                  item.crypto_id === selected?.crypto_id ? Selected : ""
                }`}
                onClick={() => setSelected(item)}
              >
                <Tooltip title={item.name} arrow>
                  <img
                    src={
                      item.image_url
                        ? encodeSpacesInUrl(item.image_url)
                        : ImgLogoPlaceholder
                    }
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "68px",
                    }}
                    alt={item.name}
                  />
                </Tooltip>
              </Item>
            </Box>
          ))}
        </Carousel>

        {selected ? (
          <CryptoOfferingCoin
            id={selected.crypto_id}
            title={selected.name}
            description={selected.description}
            chartData={chartData}
            multiCalcData={data}
            realTimeValue={realTimeValue}
            bgImg={selected.background_image_url}
            isLoading={isLoading}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default CryptoFeeds;
