import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  padding: "1.25rem 1.5rem",
  margin: "0 1.5rem",
  color: "#fff",
  height: theme.spacing(35),
  display: "flex",
  flexDirection: "column",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "black",
  // width: '350px',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

const Header = styled("div")(({
  fontSize: 11,
  fontWeight: 500,
  borderBottom: "1px solid #fff",
  maxWidth: "fit-content",
}));

const Body = styled("div")(({
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontSize: 18,
  fontWeight: 600,
}));

const Footer = styled("div")(({
  fontWeight: 600,
}));

const DownloadComponent = styled("div")(({
  cursor: "pointer",
}));

export { Container, Header, Body, Footer, DownloadComponent };
